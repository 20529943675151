import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import BoxInput from './BoxInput';

const OTPCode = ({ setCode, onCompleted }) => (
  <>
    <Typography paragraph align="center" variant="body1">
      Ingresa el código que enviamos a tu email
    </Typography>
    <Box width={250} mx="auto">
      <BoxInput
        onChange={setCode}
        onCompleted={onCompleted}
      />
    </Box>
  </>
);
OTPCode.propTypes = {
  setCode: PropTypes.func.isRequired,
  onCompleted: PropTypes.func,
};
OTPCode.defaultProps = {
  onCompleted: () => {},
};
export default OTPCode;
