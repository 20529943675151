import { useMutation } from '@apollo/client';
import { FinanceIcon, FingoPlatformBig, RateGraphIcon, ReviewDocumentIcon, VisualizeAccountIcon } from '@fingo/lib/assets';
import BicolorBackgroundBox from '@fingo/lib/components/boxes/BicolorBackgroundBox';
import TermsAndConditionsDialog from '@fingo/lib/components/dialogs/TermsAndConditionsDialog';
import { WelcomeHeader } from '@fingo/lib/components/headers';
import { ACCEPT_TERMS } from '@fingo/lib/graphql';
import { useBooleanState, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import ProgressiveImage from 'react-progressive-graceful-image';
import { useHistory } from 'react-router-dom';
import PartnerLogo from './PartnerLogo';

const TYC_BENEFITS = [{
  key: 'review-invoices-on-time',
  icon: <ReviewDocumentIcon />,
  text: 'Revisa tus facturas rechazadas a tiempo.',
},
{
  key: 'visualize-accounts',
  icon: <VisualizeAccountIcon />,
  text: 'Visaluiza tus cuentas por pagar y cuentas por cobrar.',
},
{
  key: 'best-interest-rates-financing',
  icon: <RateGraphIcon />,
  text: 'Accede a financiamiento con las mejores tasas.',
},
{
  key: 'check-key-financial-metrics',
  icon: <FinanceIcon />,
  text: 'Chequea tus principales métricas financieras.',
},
];

const TermsAndConditions = () => {
  const history = useHistory();
  const selectedCompany = useSelectedCompany();
  const [openTerms, toggleOpenTerms] = useBooleanState(false);
  const isMobile = useIsMobile();
  const [acceptTermsMutation, { loading: loadingTerms }] = useMutation(ACCEPT_TERMS, {
    variables: {
      companyId: selectedCompany?.id,
    },
    onCompleted: () => {
      if (selectedCompany.isAcepta) {
        history.push('/app/sales');
      } else if (selectedCompany.isSenegocia) {
        history.push('/app/sales/ordering/available');
      }
    },
  });

  return (
    <>
      <TermsAndConditionsDialog open={openTerms} setOpen={toggleOpenTerms} />
      <BicolorBackgroundBox
        bgcolor="common.white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight="100%"

      >
        <Stack
          direction="column"
          alignItems="center"
          alignContent="space-between"
          width={isMobile ? '100vw' : '100%'}
          height={isMobile ? '100vh' : '100%'}
          bgcolor="common.white"
          flex={1}
          borderRadius={5}
          p={2}
          pt={0}
          maxWidth="1300px"
        >
          <WelcomeHeader
            topMessage={isMobile ? '' : '¡Bienvenido'}
            headerMessage="¡Bienvenido a Fingo!"
            bottomMessage={isMobile ? '' : ' a Fingo!'}
            maxHeight={120}
            pt={isMobile ? 2 : 0}

          />
          {isMobile && <PartnerLogo />}
          <Stack direction={isMobile ? 'column' : 'row'} flex={1} justifyContent="space-between">
            {!isMobile && (
              <ProgressiveImage src={FingoPlatformBig} placeholder="">
                {(newSrc, loading) => {
                  if (loading) {
                    return (
                      <Skeleton
                        sx={{
                          width: { xs: '100%', md: '53%' },
                          maxWidth: 950,
                          height: 300,
                        }}
                        variant="rectangular"
                      />
                    );
                  }
                  return (
                    <Box
                      component="img"
                      sx={{
                        width: { xs: '100%', md: '53%' },
                        maxWidth: 950,
                        height: 'auto',
                        objectFit: 'contain',
                      }}
                      alt="Plataforma de fingo"
                      src={newSrc}
                    />
                  );
                }}
              </ProgressiveImage>
            )}
            <Stack
              flex={1}
              px={2}
              alignItems={isMobile ? 'center' : 'left'}
              justifyContent={isMobile ? 'space-evenly' : 'center'}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems={isMobile ? 'center' : 'left'}
                p={2}
              >
                <Typography
                  variant={isMobile ? 'h5' : 'h3'}
                  fontWeight={500}
                >
                  Anticipa tus facturas con Fingo
                </Typography>
                <Typography
                  fontSize={isMobile ? 14 : 24}
                  textAlign={isMobile ? 'center' : 'left'}
                >
                  Para que siempre tengas efectivo cuando lo necesites.
                </Typography>
                <Box mt={3} width={isMobile ? '80%' : 'auto'} justifyContent={isMobile ? 'center' : ''}>
                  <LoadingButton
                    fullWidth={isMobile}
                    variant="contained"
                    size="small"
                    id="button-see-my-invoices"
                    loading={loadingTerms}
                    onClick={acceptTermsMutation}
                  >
                    Ver mis facturas
                  </LoadingButton>
                </Box>
              </Box>
              <Stack mt={4} p={1.5}>
                <Typography
                  fontSize={isMobile ? 14 : 20}
                  fontWeight={isMobile ? 600 : 500}
                  textAlign={isMobile ? 'center' : 'left'}
                >
                  Beneficio de ingresar tus credenciales:
                </Typography>
                <Stack
                  direction={isMobile ? 'column' : 'row'}
                  spacing={1}
                  py={2}
                  justifyContent="space-between"
                  flexWrap={isMobile ? 'wrap' : 'nowrap'}
                >
                  {TYC_BENEFITS.map((benefit) => (
                    <Stack
                      key={benefit.key}
                      direction={isMobile ? 'row' : 'column'}
                      justifyContent={isMobile ? 'left' : ''}
                      alignItems={isMobile ? 'center' : ''}
                      spacing={2}

                    >
                      <Box>{benefit.icon}</Box>
                      <Typography> {benefit.text}</Typography>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="column">
            <Stack direction="row" alignItems="center" spacing={0.3}>
              <Typography variant="body2">
                Al presionar &quot;Ver mis facturas&quot; aceptas los
              </Typography>
              <Button
                color="primary"
                variant="link"
                onClick={toggleOpenTerms}
                size="small"
                sx={{
                  padding: 0,
                }}
              >
                Términos y condiciones
              </Button>
            </Stack>
            {!isMobile && <PartnerLogo />}
          </Stack>
        </Stack>
      </BicolorBackgroundBox>
    </>
  );
};

export default TermsAndConditions;
