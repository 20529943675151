import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import useInvoicePreColumns from '@fingo/lib/constants/invoice-pre-columns';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import dayjs from '@fingo/lib/config/dayjs';
import PropTypes from 'prop-types';
import React from 'react';

const COLUMNS = [
  'folio',
  'dateIssued',
  'receiver_sortable',
  'receiver_Rut',
  'amountWithIva',
  'orderingMonthlyRate',
  'orderingDefaultRate',
  'dateToPay',
];

const AddInvoicesToPurchaseOrdersDialogStepOne = (props) => {
  const {
    statistics,
    offers,
    loading,
    selectedOfferIds,
    setSelectedOfferIds,
    setOrderingAssignmentRequest,
    page,
    setPage,
    pageSize,
    setPageSize,
    totalCount,
  } = props;

  return (
    <>
      <Stack
        direction="row"
        divider={
          <Divider orientation="vertical" flexItem sx={{ bgColor: grey[50] }} />
        }
        spacing={3}
        bgcolor="gray.A100"
        mb={3}
        p={3}
        justifyContent="space-around"
      >
        {statistics.map(({ label, value, color }) => (
          <Box
            key={label}
            sx={{
              height: 70,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'space-around',
              justifyContent: 'space-around',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: 'bold', fontSize: 14 }}
            >
              {label}
            </Typography>
            <Typography
              variant="body2"
              sx={{ fontSize: 14, color: color || 'text' }}
            >
              {value}
            </Typography>
          </Box>
        ))}
      </Stack>
      <FingoDataGrid
        rows={offers}
        columns={useInvoicePreColumns().filter((col) => COLUMNS.includes(col.field))}
        loadingWithSkeleton={loading}
        pagination
        paginationMode="server"
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowCount={totalCount}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(ids) => {
          setSelectedOfferIds(ids);
        }}
        selectionModel={selectedOfferIds}
        density="compact"
        boxHeight={370}
        onCellEditCommit={({ id: invoiceId, value }) => {
          setOrderingAssignmentRequest((prevState) => {
            const newOrderingAssignmentRequest = [...prevState];
            const orderingAssignmentRequest = newOrderingAssignmentRequest.find(
              (orderingAssignment) => orderingAssignment.invoiceId === invoiceId,
            );
            const dateToPay = dayjs(value).format('YYYY-MM-DD');
            if (orderingAssignmentRequest) {
              orderingAssignmentRequest.dateToPay = dateToPay;
              return newOrderingAssignmentRequest;
            }

            return [...newOrderingAssignmentRequest, { invoiceId, dateToPay }];
          });
        }}
      />
    </>
  );
};

AddInvoicesToPurchaseOrdersDialogStepOne.propTypes = {
  statistics: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      color: PropTypes.string,
    }),
  ).isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  selectedOfferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedOfferIds: PropTypes.func.isRequired,
  setOrderingAssignmentRequest: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};

export default AddInvoicesToPurchaseOrdersDialogStepOne;
