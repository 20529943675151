import React, { useCallback, useMemo, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import dayjs from '@fingo/lib/config/dayjs';
import { useMutation } from '@apollo/client';
import {
  CREATE_EXTERNAL_PURCHASE_ORDER,
  ORDERING_PURCHASE_ORDERS,
} from '@fingo/lib/graphql';
import { FingoSmallDatePicker } from '@fingo/lib/components/datePickers';
import { formatRut, formatMoney } from '@fingo/lib/helpers';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import FileDownload from '@mui/icons-material/FileDownload';
import FileUpload from '@mui/icons-material/FileUpload';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Task from '@mui/icons-material/Task';
import { LoadingButton } from '@mui/lab';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { useSelectedCompany } from '@fingo/lib/hooks';
import { IVA_CL } from '@fingo/lib/constants';
import RutInput from '@fingo/lib/components/inputs/RutInput';
import { checkRut } from 'react-rut-formatter';

const numberFormatProps = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 0,
  fixedDecimalScale: true,
  valueIsNumericString: true,
  prefix: '$',
  customInput: TextField,
};
const textFieldProps = {
  variant: 'standard',
  sx: { '& label': { color: '#A8A8A8', fontSize: '0.9rem' } },
  fullWidth: true,
};
const gridInputProps = {
  md: 6,
  xs: 12,
  sx: { px: 4, py: 1 },
  alignItems: 'flex-end',
  container: true,
};
const clearFormInputs = {
  orderNumber: '',
  totalAmount: '',
  discounts: '',
  taxAmount: '',
  purchaserRut: '',
  publicationDate: dayjs(),
  comment: '',
  pdfFile: null,
};

const DialogBox = styled(Box)(() => ({
  backgroundColor: 'white',
  padding: '1rem',
  width: '100%',
  borderRadius: 16,
}));

const formFields = [
  { label: 'Número OC', type: 'string', name: 'orderNumber' },
  { label: 'Fecha de publicación', type: 'date', name: 'publicationDate' },
  { label: 'Monto total (incluye IVA)', type: 'number', name: 'totalAmount' },
  { label: 'Descuentos', type: 'number', name: 'discounts' },
  { label: 'IVA', type: 'number', name: 'taxAmount' },
  { label: 'Rut comprador', type: 'rut', name: 'purchaserRut' },
  { label: 'Comentario (Opcional)', type: 'string', name: 'comment' },
  { label: 'Cargar PDF orden de compra', type: 'file', name: 'pdfFile' },
];

const UploadPurchaseOrderDialog = ({ open, onClose }) => {
  const [excelFile, setExcelFile] = useState();
  const [ordersList, setOrdersList] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [formInputs, setFormInputs] = useState(clearFormInputs);
  const [formErrors, setFormErrors] = useState({});
  const selectedCompany = useSelectedCompany();
  const [createExternalPurchaseOrder, { loading }] = useMutation(
    CREATE_EXTERNAL_PURCHASE_ORDER,
    {
      variables: {
        externalPurchaseOrders: ordersList.map((order) => ({
          orderNumber: order.orderNumber,
          totalAmount: order.totalAmount,
          discounts: order.discounts,
          taxAmount: order.taxAmount,
          purchaserRut: order.purchaserRut,
          publicationDate: order.publicationDate.format('YYYY-MM-DD'),
          comment: order.comment,
          pdfFile: order.pdfFile,
          companyRut: selectedCompany.rut,
        })),
      },
      refetchQueries: [ORDERING_PURCHASE_ORDERS],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setFormInputs(clearFormInputs);
        setFormErrors({});
        setOrdersList([]);
        onClose();
      },
      onError: () => {
        setErrorMessage(
          'No hemos podido cargar las órdenes, contactate con tu ejecutivo.',
        );
      },
    },
  );

  const handleDeleteClick = useCallback(
    (orderNumber) => () => {
      setOrdersList((prev) => prev.filter((row) => row.orderNumber !== orderNumber));
    },
    [],
  );
  const handleInputChange = useCallback(
    (event) => {
      const { name, value, files } = event.target;
      if (name === 'pdfFile') {
        setFormInputs((oldState) => ({
          ...oldState,
          [name]: files[0],
        }));
        return;
      }
      setFormInputs((oldState) => ({
        ...oldState,
        [name]: value,
      }));
    },
    [formInputs],
  );

  const handleOnBlur = useCallback(
    (event) => {
      const { name } = event.target;
      if (name === 'totalAmount') {
        setFormInputs((oldState) => ({
          ...oldState,
          taxAmount:
            (parseInt(formInputs.totalAmount, 10) * IVA_CL) / (1 + IVA_CL),
        }));
      }
      if (name === 'orderNumber') {
        setFormInputs((oldState) => ({
          ...oldState,
          orderNumber: formInputs.orderNumber.replace(/\s/g, ''),
        }));
      }
      if (name === 'purchaserRut') {
        setFormErrors((oldState) => ({
          ...oldState,
          purchaserRut: checkRut(formInputs.purchaserRut)
            ? ''
            : 'Ingresa un rut válido',
        }));
      }
    },
    [formInputs],
  );

  const handleAddOrder = useCallback(() => {
    setOrdersList((oldState) => [...oldState, formInputs]);
    setFormInputs(clearFormInputs);
  }, [formInputs]);

  const checkHasEveryInput = useCallback(
    () => Object.entries(formInputs).filter(
      ([key, value]) => key !== 'comment' && !value,
    ).length === 0,
    [formInputs],
  );
  const checkHasInputErrors = useCallback(
    () => Object.values(formErrors).filter((value) => value.length > 0).length > 0,
    [formErrors],
  );

  const checkHasAtLeastOneOrder = ordersList.length > 0;
  const checkHasEveryFile = ordersList.every((row) => row.pdfFile);
  const checkButtonDisabled = checkHasAtLeastOneOrder && checkHasEveryFile;

  const formatAmount = useCallback((amount) => {
    const formattedAmount = formatMoney(amount);
    return `$${formattedAmount}`;
  }, []);

  const columns = useMemo(
    () => [
      { field: 'orderNumber', headerName: 'Número de orden', flex: 1 },
      {
        field: 'totalAmount',
        headerName: 'Monto total',
        width: 90,
        valueGetter: (params) => formatAmount(params.row.totalAmount),
      },
      {
        field: 'publicationDate',
        headerName: 'Fecha',
        width: 90,
        valueGetter: (params) => params.row.publicationDate.format('DD/MM/YYYY'),
      },
      {
        field: 'purchaserRut',
        headerName: 'Rut comprador',
        width: 90,
        valueGetter: (params) => formatRut(params.row.purchaserRut),
      },
      {
        field: 'pdfFile',
        headerName: 'PDF',
        width: 50,
        type: 'actions',
        getActions: (params) => {
          if (!params.row.pdfFile) {
            return [
              <GridActionsCellItem
                key="upload"
                icon={<NoteAdd />}
                label="Subir"
                color="gray"
              />,
            ];
          }
          return [
            <GridActionsCellItem
              key="pdfFile"
              icon={<Task />}
              label="pdfFile"
              color="primary"
            />,
          ];
        },
      },
      {
        field: 'actions',
        headerName: '',
        width: 30,
        type: 'actions',
        getActions: ({ id }) => [
          <GridActionsCellItem
            key="delete"
            icon={<Delete />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ],
      },
    ],
    [formatAmount, handleDeleteClick],
  );
  // eslint-disable-next-line react/prop-types
  const componentSelection = useCallback(
    ({ label, type, name, customs }) => {
      const commonProps = {
        key: name,
        name,
        label,
      };
      const cInput = customs ? { ...customs } : {};
      const inputErrorMessage = (
        <Typography variant="caption" color="error">
          {formErrors[name]}
        </Typography>
      );
      const typeSelection = {
        number: (
          <NumericFormat
            {...commonProps}
            onValueChange={(values) => handleInputChange({ target: { name, value: values.value } })}
            onBlur={() => handleOnBlur({ target: { name } })}
            value={formInputs[name]}
            {...textFieldProps}
            {...numberFormatProps}
            {...cInput}
          />
        ),
        rut: (
          <RutInput
            onChange={handleInputChange}
            onBlur={() => handleOnBlur({ target: { name } })}
            {...commonProps}
            {...textFieldProps}
            {...cInput}
            showErrorMessage={false}
          />
        ),
        string: (
          <TextField
            {...commonProps}
            value={formInputs[name]}
            onChange={handleInputChange}
            onBlur={() => handleOnBlur({ target: { name } })}
            {...textFieldProps}
            {...cInput}
          />
        ),
        date: (
          <FingoSmallDatePicker
            {...commonProps}
            closeOnSelect
            onChange={(newDate) => handleInputChange({ target: { name, value: newDate } })}
            value={formInputs[name]}
            {...textFieldProps}
            {...cInput}
          />
        ),
        file: (
          <label htmlFor={name} key={name}>
            <input
              id={name}
              type="file"
              name={name}
              onChange={handleInputChange}
              style={{ display: 'none' }}
            />
            <Button
              name="file"
              component="span"
              color="primary"
              startIcon={<FileUpload />}
              sx={{
                maxWidth: '100%',
                maxHeight: '2rem',
                marginInline: 'auto',
                fontSize: '0.7rem',
              }}
            >
              {formInputs[name] ? formInputs[name].name : label}
            </Button>
          </label>
        ),
      };
      return (
        <Grid item {...gridInputProps} key={name}>
          {typeSelection[type]}
          {formErrors[name] && inputErrorMessage}
        </Grid>
      );
    },
    [formInputs, handleInputChange, formErrors],
  );
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent sx={{ bgcolor: 'gray.A200' }}>
        <Stack spacing={2} alignItems="center">
          <DialogBox>
            <Typography variant="subtitle2">
              Para financiar tus órdenes de compra deberás completar la
              información solicitada en el formulario a continuación.
            </Typography>
            <Grid container>{formFields.map(componentSelection)}</Grid>
            <Grid container mt={3} justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleAddOrder}
                disabled={!checkHasEveryInput() || checkHasInputErrors()}
                id="add-external-purchase-order"
              >
                Agregar orden de compra
              </Button>
            </Grid>
          </DialogBox>
          <DialogBox display="none">
            <Typography variant="subtitle2">
              También puedes cargar un archivo excel con todas tus órdenes de
              compra.
            </Typography>
            <Typography variant="subtitle1">
              ¡No olvides que debes adjuntar todos los PDF de las órdenes de
              compra agregadas!
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item xs={6} sx={{ padding: '1rem' }}>
                <label htmlFor="upload-external-purchase-order-excel-input">
                  <input
                    id="upload-external-purchase-order-excel-input"
                    type="file"
                    name="excel"
                    style={{ display: 'none' }}
                    onChange={(event) => setExcelFile(event.target.files[0])}
                  />
                  <Button
                    component="span"
                    startIcon={<FileUpload />}
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '2rem',
                      marginInline: 'auto',
                      fontSize: '0.7rem',
                    }}
                    id="upload-external-purchase-order-excel"
                  >
                    {(excelFile && excelFile.name) || 'Subir excel'}
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6} sx={{ padding: '1rem' }}>
                <Button
                  startIcon={<FileDownload />}
                  sx={{
                    maxWidth: '100%',
                    maxHeight: '2rem',
                    marginInline: 'auto',
                    fontSize: '0.7rem',
                    color: '#5A5A5A',
                  }}
                  id="download-external-purchase-order-excel"
                >
                  Descargar plantilla excel
                </Button>
              </Grid>
            </Grid>
            <Grid container>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  maxWidth: '50%',
                  maxHeight: '2.5rem',
                  marginInline: 'auto',
                  fontSize: '0.7rem',
                }}
                disabled
                id="upload-excel-external-purchase-order"
              >
                Subir
              </Button>
            </Grid>
          </DialogBox>
          <DialogBox>
            <Typography variant="subtitle2">
              Lista de órdenes de compra
            </Typography>
            <Typography variant="subtitle1">
              En la tabla a continuación aparecerán tus órdenes de compra
              ¡Puedes agregar todas las que quieras!
            </Typography>
            <Box sx={{ marginBlock: '1rem', paddingBlock: '1rem' }}>
              <DataGrid
                rows={ordersList}
                columns={columns}
                getRowId={(row) => row.orderNumber}
                sx={{ fontSize: '0.6rem' }}
                autoHeight
                hideFooter
              />
            </Box>
          </DialogBox>
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
          <LoadingButton
            variant="contained"
            color="primary"
            size="small"
            loading={loading}
            disabled={!checkButtonDisabled}
            onClick={createExternalPurchaseOrder}
            id="send-external-purchase-order"
          >
            Subir OC y crear simulación
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

UploadPurchaseOrderDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UploadPurchaseOrderDialog;
