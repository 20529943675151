import React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';

const ImageOption = ({ src, alt, label, ...props }) => (
  <Box component="li" {...props} display="grid" alignItems="center" gap={2}>
    <LazyLoadImage src={src} alt={alt} width={60} height={24} />
    {label}
  </Box>
);

ImageOption.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

ImageOption.defaultProps = {
  src: null,
};

export default ImageOption;
