import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCountStep from '@fingo/lib/hooks/useCountStep';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { useTextFieldInput } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import OTPEmail from './OTPEmail';
import { useSendOtp, useVerifyOtp } from '../../hooks';
import OTPCode from './OTPCode';

const OTPInput = () => {
  const params = useParams();
  const [step, , nextStep] = useCountStep(0);
  const [email, setEmail] = useTextFieldInput();
  const [code, setCode] = useState('');
  const [otpLogin, loading, errorMessage] = useSendOtp(params, email, nextStep);
  const [
    otpVerify,
    loadingOtpVerify,
    verifyErrorMessage,
    sendCustomOtp,
  ] = useVerifyOtp(code, email);
  const component = useMemo(() => {
    if (step === 0) {
      return <OTPEmail email={email} setEmail={setEmail} />;
    }
    if (step === 1) {
      return <OTPCode setCode={setCode} onCompleted={sendCustomOtp} />;
    }
    return null;
  }, [step, email]);
  const nextButton = useMemo(() => {
    if (step === 0) {
      return (
        <LoadingButton
          variant="contained"
          color="primary"
          endIcon={<TrendingFlatIcon />}
          size="small"
          sx={{
            mx: 'auto',
          }}
          onClick={otpLogin}
          loading={loading}
        >
          Siguiente
        </LoadingButton>
      );
    }
    if (step === 1) {
      return (
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          sx={{
            mx: 'auto',
          }}
          onClick={otpVerify}
          loading={loadingOtpVerify}
        >
          Ingresar
        </LoadingButton>
      );
    }
    return null;
  }, [step, loading, loadingOtpVerify]);
  return (
    <Box width="100%">
      <Box mr="auto" ml="auto" width="fit-content" pb={2}>
        <MobileStepper
          position="static"
          steps={2}
          activeStep={step}
          variant="dots"
        />
      </Box>
      {component}
      <Box width={300} mx="auto">
        {errorMessage && (
          <Typography color="error" fullWidth align="center">
            {errorMessage}
          </Typography>
        )}
        {verifyErrorMessage && (
          <Typography color="error" fullWidth align="center">
            {verifyErrorMessage}
          </Typography>
        )}
      </Box>
      <Box mx="auto" width="fit-content" mt={4}>
        {nextButton}
      </Box>
    </Box>
  );
};

export default OTPInput;
