import React from 'react';
import PropTypes from 'prop-types';
import { useBooleanState } from '@fingo/lib/hooks';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import OrderingOperationDetailDialog from './dialogs/OrderingOperationDetailDialog';

const OrderingOperationActionButton = ({ purchaseOrder }) => {
  const [openDetail, toggleBoolean] = useBooleanState(false);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
        <Button
          variant="containedReverse"
          color="primary"
          size="small"
          onClick={toggleBoolean}
          id="purchaseOrderDetails"
        >
          Detalle
        </Button>
      </Stack>
      <OrderingOperationDetailDialog
        purchaseOrder={purchaseOrder}
        open={openDetail}
        onClose={toggleBoolean}
      />
    </>
  );
};

OrderingOperationActionButton.propTypes = {
  purchaseOrder: PropTypes.shape({
    status: PropTypes.string.isRequired,
  }).isRequired,
};

export default OrderingOperationActionButton;
